"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateToSystemTimezoneSetter = exports.ValueSetter = exports.Setter = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var TIMEZONE_UNIT_PRIORITY = 10;

class Setter {
  constructor() {
    _defineProperty(this, "priority", void 0);

    _defineProperty(this, "subPriority", 0);
  }

  validate(_utcDate, _options) {
    return true;
  }

}

exports.Setter = Setter;

class ValueSetter extends Setter {
  constructor(value, validateValue, setValue, priority, subPriority) {
    super();
    this.value = value;
    this.validateValue = validateValue;
    this.setValue = setValue;
    this.priority = priority;

    if (subPriority) {
      this.subPriority = subPriority;
    }
  }

  validate(utcDate, options) {
    return this.validateValue(utcDate, this.value, options);
  }

  set(utcDate, flags, options) {
    return this.setValue(utcDate, flags, this.value, options);
  }

}

exports.ValueSetter = ValueSetter;

class DateToSystemTimezoneSetter extends Setter {
  constructor() {
    super(...arguments);

    _defineProperty(this, "priority", TIMEZONE_UNIT_PRIORITY);

    _defineProperty(this, "subPriority", -1);
  }

  set(date, flags) {
    if (flags.timestampIsSet) {
      return date;
    }

    var convertedDate = new Date(0);
    convertedDate.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    convertedDate.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
    return convertedDate;
  }

}

exports.DateToSystemTimezoneSetter = DateToSystemTimezoneSetter;