"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Hour1To24Parser = void 0;

var _Parser = require("../Parser.js");

var _constants = require("../constants.js");

var _utils = require("../utils.js");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Hour1To24Parser extends _Parser.Parser {
  constructor() {
    super(...arguments);

    _defineProperty(this, "priority", 70);

    _defineProperty(this, "incompatibleTokens", ['a', 'b', 'h', 'H', 'K', 't', 'T']);
  }

  parse(dateString, token, match) {
    switch (token) {
      case 'k':
        return (0, _utils.parseNumericPattern)(_constants.numericPatterns.hour24h, dateString);

      case 'ko':
        return match.ordinalNumber(dateString, {
          unit: 'hour'
        });

      default:
        return (0, _utils.parseNDigits)(token.length, dateString);
    }
  }

  validate(_date, value) {
    return value >= 1 && value <= 24;
  }

  set(date, _flags, value) {
    var hours = value <= 24 ? value % 24 : value;
    date.setUTCHours(hours, 0, 0, 0);
    return date;
  }

}

exports.Hour1To24Parser = Hour1To24Parser;